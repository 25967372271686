import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface FlexContainerProps extends FlexProps {
   children?: ReactNode;
}

function FlexContainer({ children, ...rest }: FlexContainerProps) {
   return (
      <Flex
         px={["1em", "3em"]}
         py={["1em", "2em"]}
         flexDir="column"
         alignItems="center"
         {...rest}
      >
         {children}
      </Flex>
   );
}

export { FlexContainer };
