import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Header } from "../Header";
import { SideBar } from "../Sidebar";

interface ContainerProps {
   children?: ReactNode;
   showHeader?: boolean;
   showSideBar?: boolean;
}

function Container({
   children,
   showHeader = true,
   showSideBar = true,
   ...rest
}: ContainerProps) {
   return (
      <Flex flexDir="row" h="100vh" w="100vw">
         {showSideBar && <SideBar />}
         <Flex
            w="100vw"
            flexDir="column"
            overflow="auto"
            css={{
               "&::-webkit-scrollbar": {
                  width: "10px",
                  height: "5px",
               },
               "&::-webkit-scrollbar-thumb": {
                  background: "#7A6746",
                  borderRadius: "16px",
               },
               "&::-webkit-scrollbar-track": {
                  background: "#A18C6D",
               },
            }}
            {...rest}
         >
            {showHeader && <Header />}
            {children}
         </Flex>
      </Flex>
   );
}

export { Container };
