import axios from "axios";

const api = axios.create({
   baseURL: process.env.NEXT_PUBLIC_APP_API,
});

const apiV2 = axios.create({
   baseURL: process.env.NEXT_PUBLIC_APP_API_V2,
});

const ibgeApi = axios.create({
   baseURL: "https://servicodados.ibge.gov.br/api/v1",
});

export { api, ibgeApi, apiV2 };
