interface User {
   roles: string[];
   permissions: string[];
}

type ValidateUserGroupsRolesGroupsParams = {
   user: User;
   roles?: string[];
   permissions?: string[];
};

export function ValidateUserGroupsRolesGroups({
   user,
   roles,
   permissions,
}: ValidateUserGroupsRolesGroupsParams) {
   if (permissions?.length > 0) {
      const hasPermissions = permissions.some(permission => {
         const userPermissions = user.permissions.map(
            userPermission => userPermission,
         );

         return userPermissions.includes(permission);
      });

      if (!hasPermissions) {
         return false;
      }
   }

   if (roles?.length > 0) {
      const hasRoles = roles.some(role => {
         const userRoles = user.roles.map(userRole => userRole);

         return userRoles.includes(role);
      });

      if (!hasRoles) {
         return false;
      }
   }

   return true;
}
